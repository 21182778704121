<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="openModal"
        >
          <font-awesome-icon icon="file" />
        </div>
      </template>
      <span>Télecharger PDF facture</span>
    </v-tooltip>
    <!-- Extract OBLIGEE FACTURE Modal PDF -->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header-vue-oblige"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Générer un Pdf</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card mt-2">
          <!-- TYPE FACTURE -->
          <v-label class="title-tabel-global mt-3 ml-2"
            >Type de facture :</v-label
          >
          <v-checkbox
            label="Tous"
            color="#704ad1"
            hide-details
            v-model="checkAllType"
            @change="changeAllCheckTypes"
          ></v-checkbox>

          <div
            v-if="getTypeListFactureObligee.length"
            class="confirm-fac-check-oblige"
          >
            <div
              v-for="(option, index) in getTypeListFactureObligee"
              :key="index"
              class="mr-3"
            >
              <v-checkbox
                color="#704ad1"
                hide-details
                v-model="type"
                @change="
                  type.length == getTypeListFactureObligee.length
                    ? (checkAllType = true)
                    : (checkAllType = false)
                "
                :value="option.value"
                :label="option.text"
              >
              </v-checkbox>
            </div>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="#704ad1" text @click="handleExport">
            Générer
          </v-btn>
          <v-btn text @click.prevent="hideModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Extract OBLIGEE FACTURE Modal  PDF-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {},
  data() {
    return {
      dialog: false,
      checkAllType: true,
      type: [],
      error: null
    };
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    handleExport() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.$emit('exportFacturePdf', this.type);
        if (this.$refs['extract-modal-obligee-facture-pdf']) {
          this.$refs['extract-modal-obligee-facture-pdf']?.hide();
        }
      }
    },
    changeAllCheckTypes() {
      this.type =
        this.checkAllType == true
          ? this.getTypeListFactureObligee.map(item => item.value)
          : [];
    }
  },
  computed: {
    ...mapGetters([
      'chargementAppelPaiementThObligeeExportXlsFacture',
      'errorAppeliPaiementThObligeeExportXls',
      'getTypeListFactureObligee'
    ])
  },
  mounted() {
    this.type = this.getTypeListFactureObligee.map(item => item.value);
  }
};
</script>

<style scoped lang="scss">
.new-color-export-facture {
  background-color: #0e0c5e;
}
</style>
